var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loadingCheck
    ? _c("div", [
        _vm._v(" " + _vm._s(_vm.$t("forms.ResetPassword.loading")) + " ")
      ])
    : _vm.tokenExpired
    ? _c(
        "div",
        [
          _c(
            "v-alert",
            {
              staticClass: "alert-error",
              attrs: { text: "", outlined: "", type: "error" }
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("forms.ResetPassword.token_expired")) + " "
              )
            ]
          ),
          _c(
            "v-btn",
            { attrs: { to: { name: "login" }, text: "", block: "" } },
            [_vm._v(_vm._s(_vm.$t("actions.back")))]
          )
        ],
        1
      )
    : _c(
        "v-form",
        {
          attrs: { "lazy-validation": "" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.submit($event)
            }
          },
          model: {
            value: _vm.isFormValid,
            callback: function($$v) {
              _vm.isFormValid = $$v
            },
            expression: "isFormValid"
          }
        },
        [
          _c(
            "div",
            { staticClass: "reset-password-form" },
            [
              _c("cp-password-field", {
                ref: "password_input",
                attrs: {
                  autofocus: "",
                  label: _vm.$t("forms.ResetPassword.password.label"),
                  hint: _vm.$t("forms.ResetPassword.password.hint"),
                  placeholder: _vm.$t(
                    "forms.ResetPassword.password.placeholder"
                  ),
                  disabled: _vm.loading,
                  rules: _vm.rules.password
                },
                on: { input: _vm.clearErrorMessages },
                model: {
                  value: _vm.formData.password,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "password", $$v)
                  },
                  expression: "formData.password"
                }
              }),
              _c("cp-password-field", {
                attrs: {
                  label: _vm.$t(
                    "forms.ResetPassword.password_confirmation.label"
                  ),
                  hint: _vm.$t(
                    "forms.ResetPassword.password_confirmation.hint"
                  ),
                  placeholder: _vm.$t(
                    "forms.ResetPassword.password_confirmation.placeholder"
                  ),
                  disabled: _vm.loading,
                  rules: _vm.rules.password_confirmation
                },
                on: { input: _vm.clearErrorMessages },
                model: {
                  value: _vm.formData.password_confirmation,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "password_confirmation", $$v)
                  },
                  expression: "formData.password_confirmation"
                }
              }),
              _vm.differentPassword
                ? _c(
                    "v-alert",
                    { attrs: { type: "error", text: "", outlined: "" } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("forms.ResetPassword.different_password")
                          ) +
                          " "
                      )
                    ]
                  )
                : _vm._e(),
              _c(
                "v-btn",
                {
                  staticClass: "my-3 elevation-0",
                  attrs: {
                    type: "submit",
                    color: "primary",
                    block: "",
                    height: "42px",
                    disabled: !_vm.isFormValid || _vm.differentPassword,
                    loading: _vm.loading
                  }
                },
                [_vm._v(_vm._s(_vm.$t("actions.update")))]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    to: { name: "login" },
                    text: "",
                    block: "",
                    height: "42px",
                    disabled: _vm.loading
                  }
                },
                [_vm._v(_vm._s(_vm.$t("actions.cancel")))]
              )
            ],
            1
          )
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }